<template>
  <div>
    <!-- account details tab -->
    <b-card
      title="Update Profile Request Details"
      icon="feather icon-file-text"
      :before-change="validationForm"
    >
      <validation-observer ref="accountRules" tag="form">
        <b-form autocomplete="off" id="update-profile-request-form" class="p-2">
          <b-row>
            <b-col md="6">
              <b-row>
                <b-col md="3" class="pb-50 font-weight-bold">
                  Request By
                </b-col>
                <b-col md="9" class="pb-50">
                  {{ data_local.request_by_name }}
                </b-col>
              </b-row>
              <br />
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="3" class="pb-50 font-weight-bold"> Status </b-col>
                <b-col md="9" class="pb-50">
                  {{ data_local.status_label }}
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12">
              <b-form-checkbox
                v-model="isDeleteMultipleCandChecked"
                class="mb-1"
                button
              >
                <feather-icon icon="CheckSquareIcon" class="mr-25" />
                {{ checkbox_label }}
              </b-form-checkbox>
              <b-form-checkbox-group
                id="checkbox-group"
                v-model="selectedItems"
                name="selectedItems[]"
              >
                <table class="w-100 table mb-1">
                  <thead class="">
                    <tr>
                      <th class="text-nowrap">S.No.</th>
                      <th>Field Name</th>
                      <th>Field Last Value</th>
                      <th>Field New Value</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        item, index
                      ) in data_local.update_profile_request_fields"
                      v-bind:key="index"
                    >
                      <td>
                        <b-form-checkbox :value="item.hashid">
                          {{ ++index }}
                        </b-form-checkbox>
                      </td>
                      <td>
                        {{ item.request_field_name }}
                      </td>
                      <td>
                        {{
                          item.request_field_last_value
                            ? item.request_field_last_value
                            : "NA"
                        }}
                      </td>
                      <td>
                        {{
                          item.request_field_new_value
                            ? item.request_field_new_value
                            : "NA"
                        }}
                      </td>
                      <td>
                        <b-badge
                          pill
                          :variant="`${resolveUserStatusVariant(item.status)}`"
                          class="text-capitalize"
                        >
                          {{ item.status_label }}
                        </b-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-form-checkbox-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-button
                variant="success"
                class="mr-1"
                v-if="selectedItems.length > 0"
                @click="formSubmitted('approve')"
              >
                <feather-icon icon="CheckIcon" class="mr-25" />
                Approve
              </b-button>

              <b-button
                variant="danger"
                v-if="selectedItems.length > 0"
                @click="formSubmitted('reject')"
              >
                <feather-icon icon="XIcon" class="mr-25" />
                Reject
              </b-button>
            </b-col>

            <b-form-group
              v-for="(item, index) in customFields"
              :key="item.id"
              class="col-md-6"
            >
              <!-- #default="{ errors }" -->
              <validation-provider
                :name="item.name"
                rules=""
                #default="validationContext"
              >
                <b-form-group
                  v-if="item.type == 'number'"
                  :label="item.label"
                  label-for="input-1"
                >
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group
                  v-if="item.type == 'text'"
                  :label="item.label"
                  label-for="input-1"
                >
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="text"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group
                  v-if="item.type == 'url'"
                  :label="item.label"
                  label-for="input-1"
                >
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="url"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group
                  v-if="item.type == 'rating'"
                  :label="item.label"
                  label-for="input-1"
                >
                  <div class="d-flex align-items-center">
                    <star-rating
                      v-model="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :show-rating="false"
                      :star-size="30"
                    ></star-rating>
                    <span
                      v-if="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :class="`rating-btn btn ${
                        ratingdescription[
                          data_local.get_customfields_data['field_' + item.id]
                        ].class
                      }`"
                    >
                      {{
                        ratingdescription[
                          data_local.get_customfields_data["field_" + item.id]
                        ].text
                      }}</span
                    >
                    <input
                      type="hidden"
                      v-model="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                    />
                  </div>
                </b-form-group>

                <b-form-group
                  v-if="item.type == 'password'"
                  :label="item.label"
                  label-for="input-1"
                >
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="password"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group
                  v-if="item.type == 'textarea'"
                  :label="item.label"
                  label-for="input-1"
                >
                  <b-form-textarea
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    trim
                    :placeholder="item.label"
                  />
                </b-form-group>
                <b-form-group
                  v-if="item.type == 'date'"
                  :label="item.label"
                  label-for="input-1"
                >
                  <flat-pickr
                    :config="{ dateFormat: 'Y-m-d' }"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    class="form-control"
                    placeholder="Select Date.."
                  />
                </b-form-group>

                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import updateProfileRequestStoreModule from "../updateProfileRequestStoreModule";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      is_loading: {
        submit: false,
      },
      redirectUpdateProfileRequest: "",
      data_local: {
        title: "",
        get_customfields_data: [],
      },
      customFields: [],
      isDeleteMultipleCandChecked: false,
      selectedItems: [],
      checkbox_label: "Select All",
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-update-profile-requests";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        updateProfileRequestStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getCustomFields();
    this.fetch_user_data(this.$route.params.id);
  },
  watch: {
    isDeleteMultipleCandChecked(val) {
      this.selectedItems = [];
      if (val) {
        this.data_local.update_profile_request_fields.forEach((item) => {
          this.selectedItems.push(item.hashid);
        });

        this.checkbox_label = "Deselect All";
      } else {
        this.checkbox_label = "Select All";
      }
    },
  },
  methods: {
    resolveUserStatusVariant(status) {
      if (status == 0) return "warning";
      if (status == 1) return "success";
      if (status == 2) return "danger";
      if (status == 3) return "info";
      return "primary";
    },
    openWindow(link) {
      window.open(link);
    },
    fetch_user_data(userId) {
      this.$store
        .dispatch(
          "app-update-profile-requests/fetchUpdateProfileRequest",
          userId
        )
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
          // if (err.response.status === 404) {
          //   return;
          // }
          console.error(err);
        });
    },
    formSubmitted(type) {
      const self = this;
      const formData = new FormData(
        document.getElementById("update-profile-request-form")
      );

      formData.append("type", type);

      formData.append("_method", "PATCH");

      this.is_loading.submit = true;

      this.$store
        .dispatch("app-update-profile-requests/updateUpdateProfileRequest", {
          id: self.$route.params.id,
          data: formData,
        })
        .then((res) => {
          this.is_loading.submit = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Update Profile Request Updated",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Profile Request Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          this.$router.push("/update-profile-requests");
        })
        .catch((error) => {
          this.is_loading.submit = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Update Profile Request updating Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Update Profile Request updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-update-profile-requests/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
        })
        .catch((error) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'ErrorIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--credits {
  display: none;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
